export const namespaced = true;

export const state = {
    inactivityThreshold: '', //in minutes
};

export const getters = {
    getInactivityThreshold: state => {
        return state.inactivityThreshold;
    }
};

export const mutations = {
    SET_INACTIVITY_THRESHOLD(state, value){
        state.inactivityThreshold = value;
    },
};

export const actions = {
    setInitialVariables({ commit }, value){
        commit('SET_INACTIVITY_THRESHOLD', value);
    },
    startSessionCheck({ getters }){
        let inactivityThreshold = getters.getInactivityThreshold;
        //Get milliseconds until warning display
        let minutesToWarning = (inactivityThreshold) * 60000;

        return setTimeout(() => {
            //Make the call to check if user is still active
            axios.post('/portal/dashboard/check-timeout')
                    .catch(err => {
                        console.error(err);
                        logApplicationError(err)
                    })

        }, minutesToWarning);
    },
};