export const namespaced = true;

const chatRoomList = typeof chatRooms !== 'undefined' ? chatRooms : [];

export const state = {
    active_chat_room_id: null,
    current_user_id: null,
    current_user_status: null,
    new_messages: 0,
    messages: [],
    contact_list: [],
    active_chat_contact: null,
    show_chat_loader: false,
    show_file_manager: false,
    show_chat_list: false,
    message_sending: false,
    chat_room_list: chatRoomList, //This is defined in resources/views/osfw/layouts/portal
    active_files_list: [],
    are_notifications_enabled: 0
};

export const getters = {
    getChatRoomById: state => id => {
        return state.chat_room_list.find(room => room.chat_room_id === id);
    }
};

export const mutations = {
    ADD_CHAT_ROOM(state, chat_room) {
        state.chat_room_list.push(chat_room);
    },
    SET_ACTIVE_CHAT_ROOM_ID(state, chat_room_id) {
        state.active_chat_room_id = chat_room_id;
    },
    SET_MESSAGES(state, messages) {
        state.messages = messages;
    },
    SET_ACTIVE_CHAT_CONTACT(state, contact) {
        state.active_chat_contact = contact;
    },
    TOGGLE_CHAT_LOADER(state, loaderState) {
        state.show_chat_loader = loaderState;
    },
    SET_CONTACT_LIST(state, contacts) {
        state.contact_list = contacts;
    },
    SET_ACTIVE_FILES_LIST(state, files) {
        state.active_files_list = files;
    },
    TOGGLE_FILE_MANAGER(state, loaderState) {
        state.show_file_manager = loaderState;
    },
    TOGGLE_CHAT_LIST(state, loaderState) {
        state.show_chat_list = loaderState;
    },
    TOGGLE_MESSAGE_SENDING(state, messageIsSending) {
        state.message_sending = messageIsSending;
    },
    SET_NEW_MESSAGES_COUNT(state, numberOfNewMessages) {
        state.new_messages = numberOfNewMessages;
    },
    SET_CURRENT_USER_ID(state, userId) {
        state.current_user_id = userId;
    },
    SET_CURRENT_USER_STATUS(state, status) {
        state.current_user_status = status;
    },
    SET_CONTACT_CHAT_STATUS(state, obj) {
        state.contact_list.map(contact => {
            if (contact.messenger_id == obj.user_id) {
                contact.chat_status = obj.status;
            }
        });

        state.chat_room_list.map(chatRoom => {
            if (chatRoom.contact.messenger_id == obj.user_id) {
                chatRoom.contact.chat_status = obj.status;
            }
        });
    },
    SET_ARE_NOTIFICATIONS_ENABLED(state, value){
        state.are_notifications_enabled = value;
    }
};

export const actions = {
    selectChatRoom({ commit, getters, dispatch, actions }, chatObject) {
        commit("TOGGLE_CHAT_LOADER", true);
        commit('TOGGLE_FILE_MANAGER', false);
        commit('TOGGLE_CHAT_LIST', false);

        axios
            .post("/portal/dashboard/messenger/open-chat", {
                contact: chatObject.contact,
                chat_room_id: chatObject.chatRoomId
            })
            .then(res => {
                const data = res.data;
                const chatRoom = getters.getChatRoomById(data.chat_room_id);

                if (!chatRoom) {
                    commit("ADD_CHAT_ROOM", {
                        contact: chatObject.contact,
                        chat_room_id: data.chat_room_id,
                        number_of_unread: data.unread_messages,
                        files: [],
                    });

                    dispatch("subscribeToChatRoom", data.chat_room_id);
                    commit("SET_ACTIVE_FILES_LIST", []);
                } else {
                    commit("SET_ACTIVE_FILES_LIST", data.files);
                }

                commit("SET_ACTIVE_CHAT_ROOM_ID", data.chat_room_id);

                commit("SET_ACTIVE_CHAT_CONTACT", chatObject.contact);

                commit("SET_MESSAGES", data.messages);

                commit("SET_ARE_NOTIFICATIONS_ENABLED", data.enable_notifications);

                commit("TOGGLE_CHAT_LOADER", false);

            });
    },
    subscribeToChatRoom({ commit, state, dispatch }, chatRoomId) {
        Echo.private(`chat_room.${chatRoomId}`)
        .listen(
            "ChatMessageSent",
            data => {
                if (data.chatRoomId == state.active_chat_room_id) {
                    commit("SET_MESSAGES", data.messages);
                }

                if (data.sender !== state.current_user_id) {
                    state.chat_room_list.map(chatRoom => {
                        if (chatRoom.chat_room_id == data.chatRoomId) {
                            chatRoom.number_of_unread = data.unreadMessages
                        }
                    });

                    commit("SET_NEW_MESSAGES_COUNT", state.new_messages += 1);
                }

            }
        )
        .listen(
            "MessengerFileListChanged",
            data => {
                if (data.chatRoomId == state.active_chat_room_id) {
                    commit("SET_ACTIVE_FILES_LIST", data.files);
                }

                state.chat_room_list.map(chatRoom => {
                    if (chatRoom.chat_room_id == data.chatRoomId) {
                        chatRoom.files = data.files
                    }
                });
            }
        )
    },
    getContactsList({ commit }) {
        axios.get("/portal/dashboard/messenger/get-contact-list").then(res => {
            commit("SET_CONTACT_LIST", res.data.users);
        });
    },
    setMessages({ commit }, messages) {
        commit("SET_MESSAGES", messages);
    },
    scrollToBottomOfChatWindow() {
        let chatWindow = document.querySelector('.chat-window'),
            chatMessages = document.querySelector('.chat-messages');

        chatWindow.scroll({
            top: chatMessages.offsetHeight + 32, // the height of the messages plus the 2rem padding on the parent
            behavior: 'smooth'
        })
    },
    sendChatMessage({ commit, state, dispatch, getters }, message) {
        commit("TOGGLE_MESSAGE_SENDING", true);

        axios
            .post("/portal/dashboard/messenger/send-message", {
                message: message,
                chat_room_id: state.active_chat_room_id
            })
            .then(res => {
                commit("TOGGLE_MESSAGE_SENDING", false);
                dispatch("setMessages", res.data.messages)
                    .then(() => dispatch("scrollToBottomOfChatWindow"));

                const chatRoom = getters.getChatRoomById(state.active_chat_room_id);

                if (chatRoom.number_of_unread > 0) {
                    chatRoom.number_of_unread = 0;
                    axios.post('/portal/dashboard/messenger/clear-unread-count', {user_id: state.current_user_id, chat_room_id: state.active_chat_room_id})
                        .then(otherRes => {
                            commit("SET_NEW_MESSAGES_COUNT", otherRes.data.unread_messages);
                        })
                }
            })
            .catch(error => console.error(error));
    },
    listenToUserActivityChannel({ commit, state }) {
        Echo.channel(`user_activity`).listen(
            "UsersChatStatusHasChanged",
            data => {
                commit("SET_CONTACT_CHAT_STATUS", {
                    user_id: data.messengerId,
                    status: data.chatStatus
                });
            }
        );
    },
    setNewMessagesCount({commit}, newMessageCount) {
        commit("SET_NEW_MESSAGES_COUNT", newMessageCount);
    },
    setActiveFilesList({commit}, files) {
        commit("SET_ACTIVE_FILES_LIST", files);
    },
    setCurrentUserId({commit, getters, dispatch}, userId) {
        commit("SET_CURRENT_USER_ID", userId);

        Echo.private(`user_new_chat_rooms.${state.current_user_id}`).listen(
            "OpenChatRoom",
            data => {
                const chatRoom = getters.getChatRoomById(data.chatRoomId);

                if (!chatRoom) {
                    commit("ADD_CHAT_ROOM", {
                        contact: data.contact,
                        chat_room_id: data.chatRoomId,
                        number_of_unread: data.unreadMessages,
                    });

                    dispatch("subscribeToChatRoom", data.chatRoomId);
                }


            }
        );
    },
    setCurrentUserStatus({ commit }, status) {
        commit("SET_CURRENT_USER_STATUS", status);
    },
    toggleFileManager({commit, dispatch}, show) {
        commit('TOGGLE_FILE_MANAGER', show);
    },
    toggleChatList({commit, dispatch}, show) {
        commit('TOGGLE_CHAT_LIST', show);
    },
    setNotificationStatus({ commit }, {status, chatRoomId}){
        axios.post('/portal/dashboard/messenger/change-notification-status', {chat_room_id: chatRoomId, status: status})
        .then(res => commit("SET_ARE_NOTIFICATIONS_ENABLED", status))
        .catch(error => console.error(error));
    }
};
