import {
    formProcessingActions,
    formProcessingGetters,
    formProcessingMutations,
    formProcessingState,
} from "@v/Core/Global/Store/form-processing";

export const namespaced = true;

export const state = {
    ...formProcessingState,
    formId: "#bridal-registration-form",
    message: "",
    activeRecord: {
        user_name: "",
        fiance_name: "",
        messenger_id: "",
        status: "",
        email: "",
        phone: "",
        wedding_date: "",
        username: "",
        password: "",
        create_account_link_code: "",
        fiance_name: "",
        url: "",
        email: "",
        date: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip_code: "",
        budget_items: "",
        vendors_working_with: "",
        comments_questions: "",
        found: [],
        cats: [],
        found_text: "",
        event: "",
        event_registration:[],
        approximate_guest_count: "",
    },
    cats: null,
};

export const getters = {
    ...formProcessingGetters,
    getActiveRecord: (state) => {
        return state.activeRecord;
    },
    getFormId: (state) => {
        return state.formId;
    },
};

export const mutations = {
    ...formProcessingMutations,
    SET_ACTIVE_RECORD(state, record) {
        state.activeRecord = record;
    },
    SET_VALUE_IN_ACTIVE_RECORD(state, payload) {
        state.activeRecord[payload.variable] = payload.value;
    },

    SET_VALUE_IN_ACTIVE_RECORD_FOUND(state, value) {
        state.activeRecord.found.push = value;
    },
    SET_VALUE(state, payload) {
        state[payload.variable] = payload.value;
    },
};

export const actions = {
    ...formProcessingActions,
    submitForm({ commit, dispatch, state, getters }) {
        let formData = new FormData(document.querySelector("#bridal-registration-form"));

        dispatch("validateForm", "#bridal-registration-form");

        if (getters.getFormIsValid) {
            // Set the form processing variable
            dispatch("setFormProcessing", true);

            axios
                .post("/portal/users/register", formData)
                .then((res) => {
                    commit("SET_VALUE", {
                        variable: "message",
                        value: res.data.message,
                    });
                    window.location.replace(res.data.data);
                })
                .catch((error) => {
                    if (error.response) {
                        //Need to create error list
                        let errorUl = document.createElement("ul");

                        for (const key in error.response.data.errors) {
                            if (
                                error.response.data.errors.hasOwnProperty(key)
                            ) {
                                errorUl.innerHTML += `<li>${error.response.data.errors[key][0]}</li>`;
                            }
                        }

                        let errorMessageParagraph = document.createElement("p");
                        errorMessageParagraph.innerHTML =
                            error.response.data.message;

                        commit("SET_VALUE", {
                            variable: "message",
                            value:
                                errorMessageParagraph.innerHTML +
                                errorUl.innerHTML,
                        });
                    } else {
                        logApplicationError(error);
                    }
                });
        }
    },
};

export default { namespaced: true, state, getters, mutations, actions };
