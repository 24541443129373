import Vue from "vue";
import Vuex from "vuex";

import * as communicationsHub from "./modules/communications-hub";
import * as sessionExpiration from "./modules/session-expiration-modal";
import * as completeYourProfile from "./modules/complete-your-profile";
import * as bridalReg from "./modules/bridal-registration";


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        communicationsHub,
        sessionExpiration,
        completeYourProfile,
        bridalReg
    }
});
