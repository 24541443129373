import Vue from 'vue';
import store from './portal/store/store';

import BridalRegForm from './portal/Vue/BridalReg/BridalRegForm';

window.osfw_bridal_form = new Vue({
    store,
    el: 'div#bridal-registration',
    components: {
      BridalRegForm
    }
})