import Axios from "axios";

export const namespaced = true;

export const state = {
    cart: {
        products: {},
        promo: {
            coupon: {},
        },
        discountType: "",
        discount: "",
        total: "",
    },
    products: [],
    formData: {
        clientSecret: "",
        cardHolderName: "",
        _token: "",
        subscription: "",
        paymentMethod: "",
        vendor: "",
        coupon: {},
    },
    hasSubscription: false,
    success: "",
    errors: "",
    showPaymentForm: false,
    showSubscriptions: false,
    showCompleteProfile: false,
    cartHasItems: false,
    couponError: "",
    couponSuccess: "",
};

export const getters = {
    getCart: (state) => {
        return state.cart;
    },
    getProducts: (state) => {
        return state.products;
    },
    getProductsById: (state) => (id) => {
        return state.products.find((products) => products.product_id === id);
    },
    getForm: (state) => {
        return state.formData;
    },
    getProductsInCart: (state) => {
        return state.cart.products;
    },
    getCouponInCart: (state) => {
        return state.cart.promo;
    },
    getTotalInCart: (state) => {
        return state.cart.total;
    },
    getProductOptions: (state) => {
        return state.products.map((p) => {
            return {
                name: `${p.name} | ${p.product_price}/${p.price_interval}`,
                value: p.product_id,
            };
        });
    },
};

export const mutations = {
    ADD_TO_CART(state, item) {
        state.cart.products = item;
    },
    UPDATE_CART_HAS_ITEMS(state, bool) {
        state.cartHasItems = bool;
    },
    RESET_CART(state) {
        state.cart = {};
    },
    SET_PRODUCTS(state, data) {
        state.products = data;
    },
    SET_PLAN_ON_FORM(state, id) {
        state.formData.subscription = id;
    },
    RESET_PLAN_ON_FORM(state) {
        state.formData.subscription = "";
    },
    SET_PM_ON_FORM(state, data) {
        state.formData.paymentMethod = data;
    },
    RESET_FORM(state) {
        state.formData = {};
    },
    SET_SUBSCRIPTION_STATUS(state, data) {
        state.hasSubscription = data;
    },
    SET_SUCCESS(state) {
        state.success =
            "Success! You have a new subscription! Please complete the registration form to login.";
    },
    SET_SHOW_PLANS(state, bool) {
        state.showSubscriptions = bool;
    },
    SET_SHOW_PAYMENT(state, bool) {
        state.showPaymentForm = bool;
    },
    SET_SHOW_COMPLETE_PROFILE(state, bool) {
        state.showCompleteProfile = bool;
    },
    SET_ERROR(state, data) {
        state.errors = data;
    },
    SET_ERROR_COUPON(state, data) {
        state.couponError = data;
    },
    CLEAR_ERROR(state, data) {
        state.errors = "";
    },
    CLEAR_ERROR_COUPON(state) {
        state.couponError = "";
    },
    SET_COUPON(state, data) {
        state.formData.promo = data;
        state.cart.promo = data;
    },
    RESET_COUPON(state) {
        state.formData.promo = [];
        state.cart.promo = {};
        state.cart.discountType = "";
        state.cart.discount = "";
        state.cart.total = "";
    },
    SET_TOTAL(state, { discount, discountType, total }) {
        state.cart.discount = discount;
        state.cart.discountType = discountType;
        state.cart.total = total;
    },
};

export const actions = {
    determineWhatToShowInit({ commit }, subscribed) {
        if (subscribed == "true") {
            commit("SET_SHOW_COMPLETE_PROFILE", true);
        } else {
            commit("SET_SHOW_PLANS", true);
        }
    },

    fetchProducts({ commit, dispatch, getters }) {
        axios
            .get("/products")
            .then((response) => {
                let data = response.data;

                commit("SET_PRODUCTS", data);
            })
            .catch((error) => {
                let errorMessage = "Error! Could not fetch porduct list";

                commit("SET_ERROR", errorMessage);
            });
    },

    fetchCoupon({ commit, dispatch, getters }, couponCode) {
        commit("CLEAR_ERROR_COUPON");
        commit("RESET_COUPON");
        commit("UPDATE_CART_HAS_ITEMS", false);
        axios
            .get("/products/coupon/" + couponCode)
            .then((response) => {
                let data = response.data.data;

                commit("SET_COUPON", data);
                //  commit('ADD_TO_CART',data);

                let cart = getters.getCart,
                    price = cart.products.product_price;

                if (cart.promo.coupon.percent_off > 0) {
                    let discount = cart.promo.coupon.percent_off.toFixed(2),
                        discountType = "%",
                        discountAmout = (price * discount) / 100,
                        rawTotal = price - discountAmout,
                        total = rawTotal.toFixed(2);
                    commit("SET_TOTAL", { discount, discountType, total });
                } else if (cart.promo.coupon.amount_off > 0) {
                    let discountType = "$",
                        discount = (cart.promo.coupon.amount_off / 100).toFixed(2),
                        rawTotal = price - discount,
                        total = rawTotal.toFixed(2);

                    commit("SET_TOTAL", { discount, discountType, total });
                }

                commit("UPDATE_CART_HAS_ITEMS", true);
            })
            .catch((error) => {
                commit("UPDATE_CART_HAS_ITEMS", true);
                let errorMessage = "Error! Invalid Coupon Code";
                commit("SET_ERROR_COUPON", errorMessage);
            });
    },
    addToCart({ commit, getters, dispatch }, item) {
        commit("RESET_CART");
        commit("UPDATE_CART_HAS_ITEMS", false);
        commit("RESET_PLAN_ON_FORM");
        let product = getters.getProductsById(item);
        commit("ADD_TO_CART", product);
        commit("UPDATE_CART_HAS_ITEMS", true);
        commit("SET_PLAN_ON_FORM", product.price_id);
        commit("SET_SHOW_PAYMENT", true);
        commit("SET_SHOW_PLANS", false);
    },
    backToCart({ commit }, bool) {
        commit("SET_SHOW_PLANS", bool);
        commit("CLEAR_ERROR_COUPON");

        if (bool) {
            bool = false;
        }
        commit("SET_SHOW_PAYMENT", bool);
    },
    addPaymentMethod({ commit }, paymentMethod) {
        commit("SET_PM_ON_FORM", paymentMethod);
    },
    submitForm({ commit, getters, dispatch }) {
        let data = getters.getForm;

        axios
            .post("/payment", data)
            .then((response) => {
                commit("SET_SHOW_PAYMENT", false);
                commit("RESET_CART");
                commit("RESET_FORM");
                commit("SET_SUBSCRIPTION_STATUS", true);
                commit("SET_SUCCESS");
                commit("SET_SHOW_COMPLETE_PROFILE", true);
            })
            .catch((error) => {
                let warn =
                    "Error! Something happened when trying to process your subscription.";

                commit("SET_ERROR", warn);
                commit("RESET_CART");
                commit("RESET_FORM");
                commit("UPDATE_CART_HAS_ITEMS", false);
                dispatch("backToCart", true);
            });
    },
    removeItem({ commit }) {
        commit("RESET_CART");
        commit("UPDATE_CART_HAS_ITEMS", false);
        commit("RESET_PLAN_ON_FORM");
        commit("SET_SHOW_PLANS", true);
        commit("SET_SHOW_PAYMENT", false);
    },
    cancelAll({ commit }) {
        commit("RESET_FORM");
        commit("RESET_CART");
        commit("UPDATE_CART_HAS_ITEMS", false);
        commit("SET_SHOW_PLANS", true);
        commit("SET_SHOW_PAYMENT", false);
        commit("CLEAR_ERROR");
    },
};

export default { namespaced: true, state, getters, mutations, actions };
