<template>
    <div>
        <form
            id="bridal-registration-form"
            @submit.prevent="submitForm()"
            novalidate
        >
            <div
                class="alert-danger form-message"
                v-if="message"
                v-html="message"
            ></div>

            <input
                type="hidden"
                name="_token"
                v-model="csrf"
            >
            <input
                type="hidden"
                name="event"
                id="event"
                v-model="activeRecord.event"
            >

            <div class="row">
                <div class="form-group col-6">
                    <label for="user_name">Your name<span class="required">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        id="user_name"
                        title="Your name"
                        name="user_name"
                        placeholder="Jane Doe"
                        required
                        data-msg-required="Please enter your name"
                        v-model="activeRecord.user_name"
                    >
                </div>

                <div class="form-group col-6">
                    <label for="fiance_name">Your fiance's name<span class="required">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        id="fiance_name"
                        title="Your fiance's name"
                        placeholder="John Doe"
                        name="fiance_name"
                        required
                        data-msg-required="Please enter your fiance's name"
                        v-model="activeRecord.fiance_name"
                    >
                </div>

                <div class="form-group col-6">
                    <label for="bridal_email">Email<span class="required">*</span></label>
                    <input
                        class="form-control"
                        type="email"
                        id="bridal_email"
                        title="Email"
                        name="email"
                        required
                        data-msg-required="Please enter your email"
                        v-model="activeRecord.email"
                    >
                </div>

                <div class="form-group col-6">
                    <label for="bridal_phone">Phone</label>
                    <input
                        class="form-control"
                        type="tel"
                        id="bridal_phone"
                        title="Phone"
                        name="phone"
                        v-model="activeRecord.phone"
                    >
                </div>

                <div class="form-group col-3">
                    <label for="wedding_date">Wedding date<span class="required">*</span></label>
                    <input
                        class="form-control"
                        type="date"
                        id="wedding_date"
                        title="Wedding date"
                        name="wedding_date"
                        required
                        data-msg-required="Please enter a wedding date"
                        v-model="activeRecord.wedding_date"
                    >
                </div>

                <div class="form-group col-3">
                    <label for="wedding_time">Wedding time<span class="required">*</span></label>
                    <input
                        class="form-control"
                        type="time"
                        id="wedding_time"
                        title="Wedding time"
                        name="wedding_time"
                        required
                        data-msg-required="Please enter a wedding time"
                        v-model="activeRecord.wedding_time"
                    >
                </div>

                <div class="form-group col-6">
                    <label for="guest_count">Approximate guest count</label>
                    <input
                        class="form-control"
                        type="number"
                        id="guest_count"
                        title="Approximate guest count"
                        name="approximate_guest_count"
                        v-model="activeRecord.approximate_guest_count"
                    >
                </div>

                <div class="col-12">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <label for="bridal_reg_address_1">Wedding Address 1<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="text"
                                id="bridal_reg_address_1"
                                title="Address 1"
                                name="address_1"
                                required
                                data-msg-required="Please enter a wedding address"
                                v-model="activeRecord.address_1"
                            >
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="bridal_reg_address_2">Wedding Address 2</label>
                            <input
                                class="form-control"
                                type="text"
                                id="bridal_reg_address_2"
                                title="Address 2"
                                name="address_2"
                                v-model="activeRecord.address_2"
                            >
                        </div>
                        <div class="form-group col-sm-4">
                            <label for="bridal_reg_city">Wedding City<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="text"
                                id="bridal_reg_city"
                                title="City"
                                name="city"
                                required
                                data-msg-required="Please enter a wedding city"
                                v-model="activeRecord.city"
                            >
                        </div>
                        <div class="form-group col-sm-4">
                            <label for="bridal_reg_state">Wedding State<span class="required">*</span></label>
                            <select
                                class="form-control"
                                id="bridal_reg_state"
                                title="State"
                                name="state"
                                required
                                data-msg-required="Please enter a wedding state"
                                v-model="activeRecord.sate"
                            >
                                <option value>-- Select a state --</option>
                                <option
                                    :value="abbreviation"
                                    v-for="(name, abbreviation) in states"
                                    :key="abbreviation"
                                >{{ name }}</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-4">
                            <label for="bridal_reg_zip_code">Wedding Zip Code<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="text"
                                id="bridal_reg_zip_code"
                                title="Zip Code"
                                name="zip_code"
                                required
                                data-msg-required="Please enter a wedding zip code"
                                v-model="activeRecord.zip_code"
                            >
                        </div>
                    </div>
                </div>

                <div class="form-group col-12">
                    <label for="vendors_working_with">What vendors are you currently working with?</label>
                    <textarea
                        class="form-control"
                        id="vendors_working_with"
                        title="What vendors are you currently working with?"
                        name="vendors_working_with"
                        v-model="activeRecord.vendors_working_with"
                    ></textarea>
                </div>
                <div class="form-group col-12">
                    <OsfwVendorCategorySelector />
                </div>
                <div class="form-group col-12">
                    <label for="comments">Comments or questions?</label>
                    <textarea
                        class="form-control"
                        id="comments"
                        title="Comments or questions?"
                        name="comments"
                        v-model="activeRecord.comments_questions"
                    ></textarea>
                </div>
                <div class="form-group col-sm-4">
                    <label for="found">How did you find us?<span class="required">*</span></label>

                    <select
                        class="form-control"
                        id="found"
                        title="how did you find us"
                        name="found[]"
                        data-msg-required="Please select how  you find us"
                        multiple
                        required
                        @change="shouldShowFoundInput($event)"
                        v-model="activeRecord.found"
                    >
                        <option value="Google">Google</option>
                        <option value="Referral">Referral</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Wedding Show">Wedding Show</option>
                        <option value="Other">Other</option>
                    </select>

                </div>
                <div
                    class="form-group col-sm-4"
                    v-if="showFoundInput"
                >
                    <label for="found">How did you find us?<span class="required">*</span></label>

                    <input
                        type="text"
                        class="form-control"
                        id="found-text"
                        title="how did you find us"
                        name="found-text"
                        data-msg-required="Please select how you find us"
                        v-model="activeRecord.foundText"
                    >

                </div>
                <!-- COMMENTED OUT AS PER UE-1079
                <div class="form-group col-sm-4">
                    <label for="found">Select the Event(s) You'd Like to Attend</label>

                    <select
                        class="form-control"
                        id="event_registration"
                        title="Event Registration"
                        name="event_registration[]"
                        data-msg-required="Please select if you would like to attend an event."
                        multiple
                        @change="shouldShowFoundInput($event)"
                        v-model="activeRecord.event_registration"
                    >
                        <option value="Waterloo Elks Club  3/26/2023">Waterloo Elks Club  3/26/2023</option>
                    </select>

                </div> -->
            </div>

            <button
                class="btn btn-primary mt-2"
                type="submit"
            >Submit</button>
        </form>

        <div
            class="d-none text-center"
            id="bridal-registration-confirmation-message"
        >
            <h1>Success</h1>
            <p>
                Thank you <span class="user_name"></span>! <br>
                You will receive instructions on how to finish setting up your account shortly!
            </p>
            <button
                class="btn btn-primary mt-2 close-btn"
                data-dismiss="modal"
            >Close Window</button>
        </div>

    </div>
</template>

<script>
import OsfwVendorCategorySelector from '@v/Custom/Global/OsfwVendorCategorySelector.vue';
import { mapState, mapActions, mapMutations } from "vuex";
export default {
    props: ["states", "csrf"],
    components: {
        OsfwVendorCategorySelector
    },
    data() {
        return {
            showFoundInput: false,
            queryArray: [],
            selectall: false,
        };
    },
    computed: {
        ...mapState("bridalReg", {
            activeRecord: (state) => state.activeRecord,
            message: (state) => state.message,
        }),
    },
    methods: {
        shouldShowFoundInput(event) {
            let value = event.target.value;

            if (value == "Other") {
                this.showFoundInput = true;
            }
        },
        ...mapActions("bridalReg", ["submitForm"]),
        ...mapMutations("bridalReg", ["SET_VALUE_IN_ACTIVE_RECORD"]),
    },
    mounted() {
        let params = new URLSearchParams(document.location.search);
        let name = params.get("event");
        let payload = { variable: "event", value: name };
        this.SET_VALUE_IN_ACTIVE_RECORD(payload);
    },
};
</script>
